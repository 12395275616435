var Patient;
define([/* leave empty - may break OSCAR integration */], function() {
	"use strict";
	Patient = function(spec) {
		/**
		 * Expected Arguments for spec: any of the Patient fields listed below
		 * (optional) Note that spec may be a Patient object parsed from JSON;
		 * passing it into a new Patient() here will give you all the methods
		 */

		// Field initialization:
		if (spec === undefined) {
			spec = {};
		}
		var id = spec.id || null;
		var ref = spec.ref || null;
		var siteNum = spec.siteNum || null;
		// encrypted HN - uses IV from patient data block
		var encryptedHealthNumber = spec.encryptedHealthNumber || null;
		var externalPatientRef = spec.externalPatientRef || null; // eg the
																	// PSS
																	// patient #
		var reasonForVisit = spec.reasonForVisit || '';
		var apptReason = spec.apptReason || null;
		var visitType = spec.visitType || null;
		if (reasonForVisit !== '' && apptReason === null && visitType === null) {
			apptReason = reasonForVisit;
			visitType = reasonForVisit;
		}
		var chartId = spec.chartId || "";
		var demographics = $.extend({
			firstName : "",
			surname : "",
			preferredName : "",
			secondName : "",
			maidenName : "",
			title : "",
			suffix : "",
			alternateId: "",
			hn : "",
			hnProv : "",
			hnVC : "",
			hnExpiryDate : null,
			birthDate : null,
			approxAgeInDays : null,
			sex : null,
			address : null,
			familyDoc : null, // Address obj + billingNum
			clinicDoc : null, // Address obj + billingNum
			emergencyContact : null, // Address obj
			emergencyContactPhone : null, // Address obj
			language : null,
			memberStatus : null,// aka roster status
			diagnosis : null,
			emailConsent : null,
			comments: "",
			preferredPharmacy: null, // Address
			blockFeeExpiryDate: null,
			customFields: []
		// 'Y' vs 'N'
		}, spec.demographics);
		var appts = spec.appts || [];
		demographics.address = $.extend({ // Address obj
			streetAddress : "",
			line1 : "",
			line2Label : "",
			line2 : "",
			unitNumber : "",
			city : "",
			province : "",
			postalCode : "",
			homePhone : "",
			businessPhone : "",
			businessPhoneExt : "",
			mobilePhone : "",
			email : "",
			pmoc: null,
			loc : null
		}, demographics.address);
		demographics.address2 = $.extend({ // Address obj
			streetAddress : "",
			line1 : "",
			line2Label : "",
			line2 : "",
			unitNumber : "",
			city : "",
			province : "",
			postalCode : "",
			homePhone : "",
			businessPhone : "",
			businessPhoneExt : "",
			mobilePhone : "",
			email : "",
			loc : null
		}, demographics.address2);
		var cpp = {};// CList: pmhx, prob, fhx, allg, rx, tx, immu, soc
		for ( var i in spec.cpp) {
			cpp[i] = new Patient.List(spec.cpp[i]);
		}
		var notes = spec.notes || [];
		var results = spec.results || [];
		var attendingDoc = $.extend({// Clinician.java (the uploading doc)
			name : null,
			signature : null,
			billingNum : null,
			professionalId : null,
			address : null
		}, spec.attendingDoc);
		var uploadingUser = $.extend({// Clinician.java
			name : null
		}, spec.uploadingUser);

		var ptUpdate = spec.ptUpdate ? new Patient.PtUpdate(spec.ptUpdate) : null;//ptUpdate is used by referrals to store the note in this encrypted blob

		// Public
		var obj = {};
		obj.id = id;
		obj.ref = ref;
		obj.siteNum = siteNum;
		obj.externalPatientRef = externalPatientRef;
		obj.getName = function() {
			var name = "";
			if (demographics.firstName !== null) {
				name += demographics.firstName;
			}
			if (demographics.surname !== null) {
				name += " " + demographics.surname;
			}
			return name.trim();
		};
		obj.getPreferredOrFirstName = function() {
			var name = demographics.preferredName;
			if (name && name.length > 0) {
				return name;
			}
			return demographics.firstName;
		};
		obj.isMale = function() {
			return demographics.sex == "M";
		};
		obj.isFemale = function() {
			return demographics.sex == "F";
		};
		obj.getBirthDate = function() {
			try {
				return demographics.birthDate ? Date.parseDateMidnight(demographics.birthDate) : null;
			} catch (e) {
				console.error("bad dob: " + demographics.birthDate);
				return null;
			}
		};
		obj.getAge = function() {
			var dob = obj.getBirthDate();
			if (dob) {
				var millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.26;
				var years = Math.floor((new Date() - dob.getTime())
						/ millisecondsPerYear);
				return years;
			} else if (demographics.approxAgeInDays !== null) {
				return demographics.approxAgeInDays / 365;
			}
			return null;
		};
		obj.getAgeInMonths = function() {
			var dob = obj.getBirthDate();
			if (dob) {
				var millisecondsPerMonth = 1000 * 60 * 60 * 24 * (365.26 / 12);
				var months = (new Date() - dob.getTime())
						/ millisecondsPerMonth;
				return Math.floor(months);
			} else if (demographics.approxAgeInDays !== null) {
				return Math.floor(demographics.approxAgeInDays / 30);
			}
			return null;
		};
		obj.getAgeInWeeks = function() {
			var dob = obj.getBirthDate();
			if (dob) {
				var millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
				var weeks = (new Date().getTime() - dob.getTime())
						/ millisecondsPerWeek;
				return Math.floor(weeks);
			} else if (demographics.approxAgeInDays !== null) {
				return Math.floor(demographics.approxAgeInDays / 7);
			}
			return null;
		};
		obj.getAgeInDays = function() {
			var dob = obj.getBirthDate();
			if (dob) {
				var millisecondsPerDay = 1000 * 60 * 60 * 24;
				var days = (new Date().getTime() - dob.getTime())
						/ millisecondsPerDay;
				return Math.floor(days);
			} else if (demographics.approxAgeInDays !== null) {
				return demographics.approxAgeInDays;
			}
			return null;
		};
		obj.getDemog = function() {
			return obj.demographics;
		};
		obj.demographics = demographics;
		obj.appts = appts;
		obj.reasonForVisit = reasonForVisit;
		obj.apptReason = apptReason;
		obj.chartId = chartId;
		obj.visitType = visitType;
		obj.cpp = cpp;
		obj.attendingDoc = attendingDoc;
		obj.uploadingUser = uploadingUser;
		obj.notes = notes;
		obj.results = results;
		obj.ptUpdate = ptUpdate;
		return obj;
	};

	// Classes:
	Patient.Note = function(spec) {
		if (spec) {
			this.title = spec.title;
			this.text = spec.text;
			this.noteType = spec.noteType;
		}
		this.getTitle = function() {
			return this.title;
		};
		this.setTitle = function(title) {
			this.title = title;
		};
		this.getText = function() {
			return this.text;
		};
		this.setText = function(text) {
			this.text = text;
		};
	};
	Patient.Note.Type = {
		progress: "progress",
		letter: "letter",
		report: "report",
		inboundMsg: "inboundMsg",
		outboundMsg: "outboundMsg",
		confirmViewedMsg: "confirmViewedMsg",
		incompleteERequestNote: "incompleteERequestNote"
	};
	Patient.Meta = function(spec) {
		this.creationDate = spec.creationDate || null;
		this.modificationDate = spec.modificationDate || null;
		this.source = spec.source || null;
	};
	Patient.Code = function(spec) {
		this.code = spec.code || null;
		this.codeType = spec.codeType || null;
		this.desc = spec.desc || null;
	};
	Patient.Item = function(spec) {
		var key = spec.key || null;
		var desc = spec.desc || null;
		var code = spec.code ? new Patient.Code(spec.code) : null;
		var meta = spec.meta ? new Patient.Meta(spec.meta) : null;
		var data = spec.data || null;
		return {
			key : key,
			desc : desc,
			code : code,
			meta : meta,
			data : data
		};
	};
	Patient.List = function(spec) {
		var items = [];
		if (spec && spec.items) {
			for (var i = 0; i < spec.items.length; i++) {
				items.push(new Patient.Item(spec.items[i]));
			}
		}
		return {
			items : items
		};
	};
	Patient.Result = function(spec) {
		var date = spec.date || null;
		var type = spec.type || null;// eg 'lab', 'vital'
		var key = spec.testName || null;
		var val = spec.val || null;
		var normalText = spec.normalText || null;
		var abnormal = spec.abnormal || null;
		return {
			date : date,
			type : type,
			key : key,
			val : val,
			normalText : normalText,
			abnormal : abnormal
		};
	};
	Patient.PtUpdate = function(spec) {
		var progressNote = spec.progressNote || null;
		var ptUpdate = {
			progressNote : new Patient.Note(progressNote),
			emrFieldUpdates : spec.emrFieldUpdates || null,
			formValues : spec.formValues || null,
			completedForms : spec.completedForms || null,
			formMetadata: spec.formMetadata || null
		};
		
		// don't create discreteNotes if it will be entirely redundant with progressNote
		if (spec.discreteNotes && spec.discreteNotes.length > 1) {
			ptUpdate.discreteNotes = spec.discreteNotes; 
		}
		
		return ptUpdate;
	};
	return Patient;
});